
html {
  scroll-behavior: smooth;
}

button:focus { outline: none; }

/* The Overlay (background) */
/* Position the content inside the overlay */

.transBackground{
background-color: rgba(0, 0, 0, 0.75)
}


.logo{
width: 32px;
height: 32px;
}

.fade-in {
animation: fadeIn ease 10s;
-webkit-animation: fadeIn ease 10s;
-moz-animation: fadeIn ease 10s;
-o-animation: fadeIn ease 10s;
-ms-animation: fadeIn ease 10s;
}

@keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}

@-moz-keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}

@-webkit-keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}

@-o-keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}

@-ms-keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}
